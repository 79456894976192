import React from "react"

const FormGroupSelect = (props) => {
    return (
        <div className={`form-group select-group`}>
            <label htmlFor={props.id}>{props.label}</label>
            <select className={props.bg} id={props.id} name={props.id}
                    required={props.required || false} onChange={props.onChange}>
                <option value=""/>
                { props.options.map((option, index) => {
                    return (
                        <option key={index} value={option}>{option}</option>
                    );
                }) }
            </select>
        </div>
    )
}

export default FormGroupSelect;
