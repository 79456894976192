import React, {useEffect, useState} from 'react';
import { Link } from 'gatsby';
import TitleSection from "./title-section";
import BtnArrow from "../elements/btn-arrow";
import FormGroupSelect from "../elements/form-group-select";
import {getLocaleUrl} from "../../contentful/data-provider";

function JobsSection(props) {
    const content = props.content;

    const [jobs,setJobs] = useState(content);
    const [string,setString] = useState('');
    const [city,setCity] = useState('');
    const [filterInProgress,setFilterInProgress] = useState(false);

    const citiesFromContent = content.reduce((groups, item) => {
        const group = (groups[item.node.location] || []);
        group.push(item.node.location);
        groups[item.node.location] = group;
        return groups;
    }, {});

    const cities = Array.from(Object.keys(citiesFromContent));


    useEffect(() => {
        if(filterInProgress){
            filterJobs();
        }
    });

    function changeString(value){
        setString(value);
        setFilterInProgress(true)
    }

    function changeCity(value){
        setCity(String(value));
        setFilterInProgress(true)
    }

    function filterJobs(){
        setJobs(
            content.filter(function (el) {
                return el.node.title.toLowerCase().includes(string.toLowerCase()) && (city === '' ? true : el.node.location.toLowerCase().includes(city.toLowerCase()))
            })
        )

        setFilterInProgress(false)
    }

    return (
        <>
            <TitleSection content={{title: 'Open Positions', id: '0'}}/>

            <section className="pt-0 pb-[20px] md:pb-[30px]" >
                <div className={'container'}>
                    <div className="flex flex-wrap md:space-x-10 job-search">
                        <div className="basis-full md:basis-[400px]">

                            <div className={`form-group light-grey`}>
                                <label htmlFor={'search'}>{'Search'}</label>
                                <input className={'light-grey'} type={'text'} id={'search'} name={'search'} onKeyUp={e => changeString(e.target.value)}/>
                            </div>
                        </div>
                        <div className="basis-[160px]">
                            <FormGroupSelect onChange={e => changeCity(e.target.value)} id={'city'} label={'City'} options={cities} bg={`light-grey`} required={true}/>
                        </div>
                    </div>
                </div>
            </section>

            <section className="pt-0 pb-[50px] md:pb-[100px]" >
                <div className={'container'}>
                    <div className="jobs-list-section space-y-2.5">
                        {!jobs.length && 'There are no jobs matching your search'}
                        {jobs.map((element, index) => (
                            <Link key={index} className='block' to={getLocaleUrl(element.node_locale) + element.node.slug}>
                                <li className={`flex flex-row items-center justify-between px-[30px] py-5 job-list-item`}>
                                    <div className="flex flex-wrap">
                                        <span className='basis-full city'>
                                            {element.node.location}
                                        </span>
                                        <h2 className='mt-1.5 basis-full title'>
                                            {element.node.title}
                                        </h2>
                                    </div>
                                    <BtnArrow className={'hidden md:block'} class={`btn-black`} size={'btn-xs'} />
                                </li>
                            </Link>
                        ))}
                    </div>
                </div>
            </section>
        </>
    )
}

export default JobsSection;
