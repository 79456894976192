import * as React from "react";
import { graphql } from "gatsby";
// import { Helmet } from "react-helmet";
import Layout from "../../components/layout";
import { DataProvider } from "../../contentful/data-provider";
import JobsSection from "../../components/sections/jobs-section";
import Seo from "../../components/seo";

const JobsPage = ({ data }) => {
	const dataProvider = new DataProvider(data);
	const title = data.allContentfulPage.edges[0].node.title;

	return (
		<Layout dataProvider={dataProvider}>
			<Seo dataProvider={dataProvider} title={`Glassview | ${title}`} />
			<main className='pt-24 lg:pt-24'>
				<JobsSection content={data.allContentfulJobPost.edges} />
			</main>
		</Layout>
	);
};

export default JobsPage;

export const query = graphql`
	query ($locale: String!) {
		allContentfulPage(filter: { contentful_id: { eq: "5PJm7QeSZQYdv3s3oCaJwT" }, node_locale: { eq: $locale } }) {
			edges {
				node {
					...CurrentPageDetails
				}
			}
		}
		allContentfulJobPost(filter: { node_locale: { eq: $locale } }) {
			edges {
				node {
					id
					node_locale
					title
					slug
					location
					body {
						raw
					}
				}
			}
		}
		allContentfulMenu(filter: { node_locale: { eq: $locale } }) {
			edges {
				node {
					...Menus
				}
			}
		}
	}
`;

//comentedhead
